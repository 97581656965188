import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
   constructor(private http: HttpClient) { }
   sendEmail(name, email, message): Promise<any> {
    let options = {
      headers: { 'Content-Type': 'application/json' }
    }
          const url = `https://us-central1-urban-abode-nashville.cloudfunctions.net/emailSender?dest=${email}&message=${message}&name=${name}`;
          console.log(url);
          return this.http.get(url).toPromise()
               .then((res) => res)
                     .catch((err) => console.log(err));
      }
}