import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  modalImgSrc: string;
  modalDisplay: string;

  title = 'Gallery - Urban Abode Nashville | Interior Design';

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

  openModal(event) {
    const imgSrc = event.path[0].src;
    this.modalDisplay = "block";
    this.modalImgSrc = imgSrc;
  }

 @HostListener('window:keyup', ['$event'])
  onKeyDown(event: any) {    
    if (event.key === "Escape") {
      this.closeModal();
    }
  }

  closeModal() {
    this.modalDisplay = "none";
    this.modalImgSrc = ""
  }
}
