import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  isValidFormSubmitted = null
  contactFormSubmitted: boolean;
  contactForm: FormGroup;
  showMsg: boolean;
  isDisabled: boolean;
  title = 'Contact - Urban Abode Nashville | Interior Design';
  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService,
    private titleService: Title
    ) { 
    this.createContactForm();
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

  createContactForm(){
    this.contactForm = this.formBuilder.group({
      fullName: ['', Validators.required],  
      email: ['', [Validators.required, Validators.email]],
      message: ['']
    });
  }

  onSubmit() {
    this.contactFormSubmitted = true;
    this.isValidFormSubmitted = false;
    if (this.contactForm.invalid) {
      return;
    }
    this.emailService.sendEmail(this.contactForm.value.fullName, this.contactForm.value.email, this.contactForm.value.message);
    this.showMsg = true;
    this.isDisabled = true;
  }
}
